export const mapBorough = (name) => {
    if (name === "manhattan") return "Manhattan";
    else if (name === "bronx") return "Bronx";
    else if (name === "staten island") return "Staten Island";
    else if (name === "queens") return "Queens";
    else if (name === "brooklyn") return "Brooklyn";
    else if (name === "Manhattan") return "manhattan";
    else if (name === "Bronx") return "bronx";
    else if (name === "Staten Island") return "staten island";
    else if (name === "Queens") return "queens";
    else if (name === "Brooklyn") return "brooklyn";
}

